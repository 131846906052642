<template>
  <div class="main-content bg-site-color">
    <div class="header">
      <div class="site-title">
        <a href="/" class="nav-link">
          <h1>HOMECOOK</h1>
        </a>
      </div>
      <!-- Navigation -->
      <div class="navigation">
        <!-- Links -->
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link class="nav-link" to="/" eaxct>Explore</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/all-recipes" eaxct>All Recipes</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/ingredients-list-category" eaxct>My List</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/favorite-recipes" eaxct>My Recipes</router-link>
          </li>
          <li v-if="loggedIn === false" class="nav-item">
            <a class="nav-link" @click="userModal=true,loginModal=true">Create Recipe</a>
          </li>
          <li v-if="loggedIn === true" class="nav-item">
            <router-link class="nav-link" to="/create-recipe" eaxct>Create Recipe</router-link>
          </li>
          <li v-if="loggedIn === true" class="nav-item">
            <router-link class="nav-link" to="/my-reviews" eaxct>My Reviews</router-link>
          </li>
          <li v-if="loggedIn === false" class="nav-item">
            <a class="nav-link" @click="userModal=true,loginModal=true">Log In</a>
          </li>
          <li v-if="loggedIn === true" class="nav-item">
            <a class="nav-link" @click="logout">Log Out</a>
          </li>
        </ul>
        <!-- Links -->
      </div>
    </div>
    <!-- Page content -->
    <div class="page-content">
      <loading :active.sync="isLoading" :can-cancel="false" color="#ffaf6c" loader="bars" background-color="#000"
        :is-full-page="true"></loading>
      <slide-y-up-transition mode="out-in" origin="center top">
        <router-view ref="child"></router-view>
      </slide-y-up-transition>
    </div>
    <!-- Login Modal -->
    <slide-y-up-transition mode="out-in" origin="center top">
      <div id="loginModal" v-if="!loggedIn && userModal" class="modalDiv">
        <div class="body">
          <button class="p-0 close"><i class="fa fa-times"
              @click="userModal=false,loginModal=false,regModal=false"></i></button>
          <div class="modal-switcher">
            <button :class="['btn', { 'active' : loginModal }]" type="button" @click="showModal">Login</button>
            <button :class="['btn', { 'active' : regModal }]" type="button" @click="showModal">Register</button>
          </div>
          <div class="content login" v-if="!loggedIn && loginModal">
            <p>Login here to be able to create your own recipes and give ratings to other recipes.</p>
            <div class="form-group">
              <label>Email Address</label>
              <input v-validate="'required|email'" v-model="user.email" class="form-control" type="email" name="email"
                placeholder="Enter Email address" />
              <span v-show="errors.has('email')" class="help is-danger">{{ errors.first('email') }}</span>
            </div>
            <button class="btn login" type="button" @click="login">Login</button>
          </div>
          <!-- register body -->
          <div class="content register" v-if="!loggedIn && regModal">
            <p>Register here to be able to create your own recipes and give ratings to other recipes.</p>
            <div class="form-group">
              <label>Name</label>
              <input v-validate="'required|min:3'" v-model="user.name" class="form-control" type="text" name="name"
                placeholder="Enter Name" />
              <span v-show="errors.has('name')" class="help is-danger">{{ errors.first('name') }}</span>
            </div>
            <div class="form-group">
              <label>Email Address</label>
              <input v-validate="'required|email'" v-model="user.email" class="form-control" type="email" name="email"
                placeholder="Enter Email address" />
              <span v-show="errors.has('email')" class="help is-danger">{{ errors.first('email') }}</span>
            </div>
            <button class="btn register" type="button" @click="register">Register</button>
          </div>
        </div>
      </div>
    </slide-y-up-transition>
  </div>

</template>
<script>
  import {
    SlideYUpTransition
  } from 'vue2-transitions'

  import Loading from 'vue-loading-overlay';
  import axios from 'axios';
  import 'vue-loading-overlay/dist/vue-loading.css';

  export default {
    name: 'site-layout',
    components: {
      SlideYUpTransition,
      Loading
    },
    data() {
      return {
        loginModal: false,
        regModal: false,
        userModal: false,
        isLoading: false,
        loggedIn: false,
        user: {},
        year: new Date().getFullYear(),
        showMenu: false,
        showContactUsDropDown: false,
        config: {
          headers: {
            Accept: 'application/json'
          }
        },
      }
    },
    methods: {
      showModal: function () {
        this.$nextTick(() => {
          this.errors.clear();
          this.$nextTick(() => {
            this.$validator.reset();
          });
        });
        this.user = {};
        this.regModal = !this.regModal;
        this.loginModal = !this.loginModal;
      },
      addWebUser: function () {
        axios.get("/api/add-web-user", this.config)
          .then(result => {
            //store client data locally
            localStorage.setItem('id', result.data.user.id);
            localStorage.setItem('device_id', result.data.user.device_id);
          }, error => {
            console.log(error);
          });
      },
      login: function () {
        this.$validator.validate().then((result) => {
          if (result) {
            this.isLoading = true;
            this.user.device_id = localStorage.getItem('device_id');
            axios.post("/api/login-web-user", this.user, this.config)
              .then(result => {
                this.isLoading = false;
                this.loginModal = false;
                this.userModal = false;
                //store client data locally
                if (result.data.value == 'login') {
                  this.user = {};
                  this.$toast.open({
                    message: "Logged in successfully.",
                    type: 'success',
                  });
                  localStorage.setItem('id', result.data.user.id);
                  localStorage.setItem('name', result.data.user.name);
                  localStorage.setItem('email', result.data.user.email);
                  this.loggedIn = true;
                  var name = this.$router.currentRoute.name;
                  if (name == 'CreatedRecipe') {
                    this.$refs.child.getAllCreatedRecipes();
                  }
                }
                if (result.data.value == 'pending-verification') {
                  this.user = {};
                  this.$toast.open({
                    message: "Email verification pending.",
                    type: 'info',
                  });
                }
                if (result.data.value == 'email_exist') {
                  this.user = {};
                  this.$toast.open({
                    message: "Email already registered.",
                    type: 'info',
                  });
                }
              }, error => {
                this.userModal = false;
                this.loginModal = false;
                if (error.response.data.value === 'user-not-found') {
                  this.$toast.open({
                    message: "Email not registered, please register your account.",
                    type: 'error',
                  });
                }
                console.log(error);
              });
          }
        }).catch(() => {});
      },
      register: function () {
        this.$validator.validate().then((result) => {
          if (result) {
            this.isLoading = true;
            this.user.device_id = localStorage.getItem('device_id');
            axios.post("/api/register-web-user", this.user, this.config)
              .then(result => {
                this.isLoading = false;
                this.userModal = false;
                this.regModal = false;
                if (result.data.value == 'pending-verification') {
                  this.user = {};
                  this.$toast.open({
                    message: "Email verification pending.",
                    type: 'info',
                  });
                }
                if (result.data.value == 'register') {
                  this.user = {};
                  this.$toast.open({
                    message: "Registration successful, please verify your email.",
                    type: 'success',
                  });
                }
                if (result.data.value == 'email_exist') {
                  this.user = {};
                  this.$toast.open({
                    message: "Email already registered.",
                    type: 'info',
                  });
                }
              }, error => {
                this.userModal = false;
                this.regModal = false;
                this.$toast.open({
                  message: "Something went wrong.",
                  type: 'error',
                });
                return error;
              });
          }
        }).catch(() => {});
      },
      logout: function () {
        var name = this.$router.currentRoute.name;
        localStorage.setItem('name', undefined);
        localStorage.setItem('email', undefined);
        this.loggedIn = false;
        this.$swal(
          "Success",
          "Logged out successfully.",
          "success"
        );
        if (name == 'CreateRecipe' || name == 'MyReviews') {
          this.$router.push({
            path: "/"
          });
        }
        if (name == 'CreatedRecipe') {
          this.$refs.child.getAllCreatedRecipes();
        }
      }
    },
    mounted: function () {
      const device_id = localStorage.getItem('device_id');
      if (device_id === undefined || device_id === 'undefined' || device_id === null) {
        this.addWebUser();
      }
      if (localStorage.getItem('email') === 'undefined' || localStorage.getItem('email') === undefined) {
        this.loggedIn = false;
      } else {
        this.loggedIn = true;
      }
    },
    computed: {
      currentRouteName() {
        return this.$route.name;
      }
    }
  }
</script>
<style>
  .modalDiv {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .6);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: baseline;
    padding-top: 50px;
  }

  .modalDiv .body {
    background-color: #fff;
    width: 50%;
    border-radius: 10px;
    padding: 40px;
    position: relative;
  }

  .modalDiv .body p {
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    color: rgb(24, 24, 24);
  }

  .modalDiv .body input {
    border-radius: 20px;
    padding-left: 10px;
    color: #444;
  }

  .modalDiv .body label {
    font-weight: 600;
    margin-left: 10px;
  }

  .modalDiv .body .btn.login,
  .modalDiv .body .btn.register {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    color: #fff;
    border-radius: 20px;
    background-color: #ce3770;
    box-shadow: 2px 3px 5px -2px #51791f41;
  }

  .modalDiv .body .close {
    background-color: transparent;
    padding: 0;
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .modalDiv .body .close i {
    font-size: 18px;
  }

  .modal-switcher {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }

  .modal-switcher .btn {
    width: 200px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    color: #000;
    background-color: #fff;
    border-radius: 20px;
    border: 1px solid gray;
    margin: 0;
  }

  .modal-switcher .btn.active {
    background-color: #ce3770;
    color: #fff;
    border: 0;
    margin: 0 !important;
  }

  .modal-switcher .btn:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
    margin-right: 0;
  }

  .modal-switcher .btn:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0;
  }

  .modal-switcher .btn:hover {
    transform: none;
  }

  .nav-link {
    cursor: pointer;
  }

  #loginModal {
    z-index: 9999999;
  }

  @media (max-width: 767.98px) {
    .main-content .header {
      display: block !important;
    }

    .main-content .header .navigation {
      display: block !important;
    }

    .main-content .header .navbar-nav {
      display: block !important;
    }

    .header {
      text-align: center;
    }

    .VueCarousel-pagination {
      margin-top: -52px !important;
    }
  }
</style>