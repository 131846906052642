/*!

=========================================================
* Vue Argon Dashboard - v1.1.1
=========================================================

* Product Page: https://www.creative-tim.com/product/vue-argon-dashboard
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/vue-argon-dashboard/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import './registerServiceWorker'
import ArgonDashboard from './plugins/argon-dashboard'
import VueLocalStorage from 'vue-localstorage'
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'
import VeeValidate from 'vee-validate';
import VueCarousel from 'vue-carousel';
import VueSweetalert2 from 'vue-sweetalert2';
import Embed from 'v-video-embed'
import VueImageLoader from '@kevindesousa/vue-image-loader'
import VueLazyload from 'vue-lazyload'
import VueYouTubeEmbed from 'vue-youtube-embed'
import Vuelidate from 'vuelidate';
// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
// axios.defaults.baseURL = "https://bothofus.se/repo/Projects/development/cookbook/BE/public/";

Vue.config.productionTip = false
Vue.use(VueYouTubeEmbed)
Vue.use(VueLocalStorage);
Vue.use(VueCarousel);
Vue.use(VeeValidate);
Vue.use(Embed);
Vue.use(VueImageLoader)
Vue.use(Vuelidate)
Vue.component('pagination', require('laravel-vue-pagination'));

Vue.use(VueSweetalert2);

// image lazy load with options
const loadimage = require('./assets/loading.gif')
const errorimage = require('./assets/img-error.png')

Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: errorimage,
  loading: loadimage,
  attempt: 1
})

Vue.use(VueToast, {
  duration: 2000,
  type: 'default',
  position: 'bottom-right'
});

Vue.use(ArgonDashboard)
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
