import Vue from 'vue'
import Router from 'vue-router'
import SiteLayout from '@/layout/SiteLayout'
Vue.use(Router)

export default new Router({
  linkExactActiveClass: 'active',
  mode: "history",
  routes: [
    {
      path: '/',
      name: 'home',
      component: SiteLayout,
      children: [
        {
          path: '/',
          name: 'home',
          component: () => import(/* webpackChunkName: "demo" */ './views/Home.vue')
        },
        {
          path: '/trending-recipes',
          name: 'TrendingRecipes',
          component: () => import(/* webpackChunkName: "demo" */ './views/TrendingRecipes.vue')
        },
        {
          path: '/popular-recipes',
          name: 'PopularRecipes',
          component: () => import(/* webpackChunkName: "demo" */ './views/PopularRecipes.vue')
        },
        {
          path: '/recipe-details/:recipe_id',
          name: 'RecipesDetails',
          component: () => import(/* webpackChunkName: "demo" */ './views/Recipes/RecipeDetails.vue')
        },
        {
          path: '/verify-user/:user_id/:key',
          name: 'VerifyUser',
          component: () => import(/* webpackChunkName: "demo" */ './views/VerifyUser.vue')
        },
        {
          path: '/create-recipe',
          name: 'CreateRecipe',
          component: () => import(/* webpackChunkName: "demo" */ './views/Recipes/Create.vue')
        },
        {
          path: '/favorite-recipes',
          name: 'FavoriteRecipe',
          component: () => import(/* webpackChunkName: "demo" */ './views/Recipes/Favorite.vue')
        },
        {
          path: '/created-recipes',
          name: 'CreatedRecipe',
          component: () => import(/* webpackChunkName: "demo" */ './views/Recipes/CreatedRecipes.vue')
        },
        {
          path: '/edit-my-recipe/:recipe_id',
          name: 'EditUserRecipe',
          component: () => import(/* webpackChunkName: "demo" */ './views/Recipes/Edit.vue')
        },
        {
          path: '/my-reviews',
          name: 'MyReviews',
          component: () => import(/* webpackChunkName: "demo" */ './views/MyReviews.vue')
        },
        {
          path: '/ingredients-list-category',
          name: 'IngredientsListCategory',
          component: () => import(/* webpackChunkName: "demo" */ './views/IngredientsListCategory.vue')
        },
        {
          path:'/ingredients-list-category/:name/:id',
          name: 'category-ingredients',
          component: () => import(/* webpackChunkName: "demo" */ './views/IngredientsList.vue')
        },
        {
          path:'/show-ingredient-recipes/',
          name: 'show-ingredient-recipes',
          component: () => import(/* webpackChunkName: "demo" */ './views/Recipes/IngredientRecipes.vue')
        },
        {
          path: '/shopping-list',
          name: 'ShoppingList',
          component: () => import(/* webpackChunkName: "demo" */ './views/ShoppingList.vue')
        },
        {
          path: '/all-recipes',
          name: 'AllRecipes',
          component: () => import(/* webpackChunkName: "demo" */ './views/AllRecipes.vue')
        },
        {
          path: '/profile',
          name: 'profile',
          component: () => import(/* webpackChunkName: "demo" */ './views/UserProfile.vue')
        },
      ]
    }
  ]
})
